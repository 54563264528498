<template>
  <div class="relative h-screen max-w-screen bg-coolGray-900 font-stolzl flex flex-col justify-between">
    <div class="w-full h-full flex flex-col justify-center items-center">

      <!-- NEWSLETTER-->
      <div class="w-full md:w-3/4 flex flex-col justify-center items-center px-4 md:px-8">
        <div class="w-auto h-auto overflow-hidden">
          <div :ref="setRef" data-anim="0" class="font-semibold text-4xl md:text-6xl text-coolGray-50 opacity-0">Hello, let's climb.</div>
        </div>
        <div class="w-full h-auto overflow-hidden mt-3 flex flex-col justify-center items-center">
          <div v-if="!submitted" :ref="setRef" data-anim="0" class="font-light text-xl md:text-2xl text-coolGray-50 w-full md:w-3/4 text-center opacity-0">
            <p>{{text.newsletter}}</p>
          </div>
        </div>
        <form v-if="!submitted" id="newsletter" @submit.prevent="submit" class="flex flex-row justify-center items-center w-full md:w-4/6 px-4 py-4 bg-white mt-5">
          <input v-model="email" type="email" placeholder="Email" class="font-light text-coolGray-900 placeholder-coolGray-900 appearance-none outline-none w-8/12 md:w-9/12"/>
          <input type="submit" :value="text.send" class="font-light text-right text-coolGray-900 placeholder-coolGray-900 bg-white appearance-none outline-none w-4/12 md:w-3/12 cursor-pointer"/>
        </form>
        <div v-else class="font-light text-xl md:text-2xl text-coolGray-50 mt-3 w-full md:w-3/4 text-center">{{text.success}}</div>
      </div>
      <!-- NEWSLETTER-->

    </div>
    <!-- BOTTOM LINE-->
    <div class="w-full flex flex-col md:flex-row justify-between items-center p-8 mb-32 md:mb-0">
      <div class="flex flex-row justify-center items-center">
        <div class="text-xs font-extralight text-coolGray-200 cursor-pointer" @click="changeLang('DE')">Deutsch</div>
        <div class="text-sm font-extralight text-coolGray-200 px-2">|</div>
        <div class="text-xs font-extralight text-coolGray-200 cursor-pointer" @click="changeLang('EN')">English</div>
      </div>
      <div class="flex flex-col md:flex-row justify-center items-center text-xs font-extralight text-coolGray-500">
        <router-link :to="{ name: 'AGB' }" class="transition duration-200 ease-out cursor-pointer mx-5 hover:text-coolGray-50 py-1 md:py-0">{{text.abg}}</router-link>
        <router-link :to="{ name: 'Datenschutz' }" class="transition duration-200 ease-out cursor-pointer mx-5 hover:text-coolGray-50 py-1 md:py-0">{{text.datenschutz}}</router-link>
        <router-link :to="{ name: 'Widerrufsbelehrung' }" class="transition duration-200 ease-out cursor-pointer mx-5 hover:text-coolGray-50 py-1 md:py-0">{{text.widerrufsbelehrung}}</router-link>
        <router-link :to="{ name: 'Content', params: { slug: 'kontakt' } }" class="transition duration-200 ease-out cursor-pointer mx-5 hover:text-coolGray-50 py-1 md:py-0">{{text.kontakt}}</router-link>
        <router-link :to="{ name: 'Impressum' }" class="transition duration-200 ease-out cursor-pointer mx-5 hover:text-coolGray-50 py-1 md:py-0">{{text.impressum}}</router-link>
        <a href="https://www.instagram.com/rewhiteclimbing/" target="_blank" class="group flex flex-row justify-center items-center py-1 mx-5 gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out w-4 h-4 group-hover:text-coolGray-50 fill-current" viewBox="0 0 24 24">
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
          </svg>
          <div class="transition duration-200 ease-out cursor-pointer group-hover:text-coolGray-50  md:py-0">Instagram</div>
        </a>
        <p class="mr-2">Webdesign & Development by </p>
        <a href="https://pola-purpose.de/de/" target="_blank" class="underline"> Pola | Digital Agency Hamburg</a>
      </div>
      <div class="flex flex-row justify-center items-center text-coolGray-500">
        <div class="text-lg font-extralight pt-1.5">©</div>
        <div class="text-xs font-extralight">rewhite-climbing 2024</div>
      </div>
    </div>
    <!-- BOTTOM LINE-->

  </div>
</template>

<script>
import intersectionObserver from '../mixins/intersectionObserver.vue';

export default {
  name: 'Footer',
  mixins: [intersectionObserver],

  data() {
    return {
      email: null,
      submitted: false,
    };
  },

  computed: {
    text() {
      return this.$store.state.lang.footer[this.$store.state.lang.currentLang];
    },
    lang() {
      return this.$store.state.lang.currentLang;
    },
  },

  mounted() {
    this.submitted = false;
  },

  methods: {

    changeLang(lang) {
      this.$store.commit('setLang', lang);
    },

    submit() {
      this.submitted = true;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: this.email, lang: this.lang }),
      };
      fetch('https://hook.integromat.com/y2okwqpr0kv8gdtemvybpf4y2e5g7dm6', requestOptions);
    },

  },
};
</script>
